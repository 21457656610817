import {
  ID_DOCUMENTS,
  type IdDocumentsType,
} from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { insertIf } from '@backmarket/utils/collection/insertIf'

export interface UnformattedIdentityPayload {
  idCardBothSidesFile?: File
  idCardFrontFile?: File
  idCardBackFile?: File
}

export const ACCEPTED_FILE_EXTENSIONS = [
  // PNG
  '.png',
  'image/png',

  // JPG
  '.jpg',
  'image/jpg',
  '.jpeg',
  'image/jpeg',

  // PDF
  '.pdf',
  'application/pdf',
]

export const INITIAL_FORM_VALUES = {
  idCardBackFile: [],
  idCardFrontFile: [],
  idCardBothSidesFile: [],
  bothSidesInOneFile: false,
}

export const KB = 1024
export const MB = 1024 * KB

export const MAX_FILES = 1
export const MAX_FILE_SIZE = 4 * MB
export const MIN_FILE_SIZE = 100 * KB

export const ID_CARD_ERROR_TYPES = {
  MAX_FILES_EXCEEDED: 'MAX_FILES_EXCEEDED',
  MAX_FILE_SIZE_EXCEEDED: 'MAX_FILE_SIZE_EXCEEDED',
  MAX_FILES_SIZE_EXCEEDED: 'MAX_FILES_SIZE_EXCEEDED',
  MIN_FILE_SIZE_NOT_EXCEEDED: 'MIN_FILE_SIZE_NOT_EXCEEDED',
  WRONG_FILE_EXTENSION: 'WRONG_FILE_EXTENSION',
}

/**
 * Format `size` to human readable size units (Gb, Mb, Kb…)
 *
 * @param {Number} size
 * @return {String}
 */
export const formatFileSize = (size: number) => {
  const GIGABYTES_QUOTIENT = 1000000000
  const MEGABYTES_QUOTIENT = 1000000
  const KILOBYTES_QUOTIENT = 1000

  const gigabytes = size / GIGABYTES_QUOTIENT
  if (gigabytes > 1) return `${gigabytes.toFixed(2)} GB`

  const megabytes = size / MEGABYTES_QUOTIENT
  if (megabytes > 1) return `${megabytes.toFixed(2)} MB`

  const kilobytes = size / KILOBYTES_QUOTIENT
  if (kilobytes > 1) return `${kilobytes.toFixed(2)} KB`

  return `${size} Bytes`
}

export const identityPayloadAdapter = (
  {
    idCardFrontFile,
    idCardBackFile,
    idCardBothSidesFile,
  }: UnformattedIdentityPayload,
  type: IdDocumentsType,
) => {
  const isFormMulti = Boolean(idCardFrontFile && idCardBackFile)

  return {
    ...insertIf(Boolean(isFormMulti && type === ID_DOCUMENTS.ID_CARD), {
      ID_CARD_FRONT: idCardFrontFile,
      ID_CARD_BACK: idCardBackFile,
    }),
    ...insertIf(
      Boolean(
        isFormMulti && type === ID_DOCUMENTS.DRIVING_LICENCE && idCardFrontFile,
      ),
      {
        DRIVING_LICENCE_FRONT: idCardFrontFile,
        DRIVING_LICENCE_BACK: idCardBackFile,
      },
    ),
    ...insertIf(
      Boolean(
        !isFormMulti && type === ID_DOCUMENTS.ID_CARD && idCardBothSidesFile,
      ),
      {
        ID_CARD: idCardBothSidesFile,
      },
    ),
    ...insertIf(
      Boolean(
        !isFormMulti && type === ID_DOCUMENTS.PASSPORT && idCardBothSidesFile,
      ),
      {
        PASSPORT: idCardBothSidesFile,
      },
    ),
  }
}
